import React from 'react'
import MeetNavigation from "../../components/meetNavigation";

export default function Settings() {
    return (
        <main className="max-w-7xl mx-auto">
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                    <MeetNavigation/>
                </aside>

                {/* details */}
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">

                </div>
            </div>
        </main>
    )
}
